import React, { useState, useEffect } from "react";
import "./SimpleTextComponent.css";
import PopupInfo from "../PopupInfo/PopupInfo";

const SimpleTextComponent = (props: any) => {
  const [isMouseOverP, setIsMouseOverP] = useState(false);
  const [isMouseOverPopup, setIsMouseOverPopup] = useState(false);
  const [isScrolling, setIsScrolling] = useState(false);

  const handleMouseEnterP = () => {
    if (!isScrolling) {
      setIsMouseOverP(true);
    }
  };

  const handleMouseLeaveP = () => {
    if (!isScrolling) {
      setIsMouseOverP(false);
    }
  };

  const handleMouseEnterPopup = () => {
    if (!isScrolling) {
      setIsMouseOverPopup(true);
    }
  };

  const handleMouseLeavePopup = () => {
    if (!isScrolling) {
      setIsMouseOverPopup(false);
    }
  };

  const handleScroll = () => {
    setIsScrolling(true);
    setTimeout(() => {
      setIsScrolling(false);
    }, 100);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return props.data?.length > 500 ? (
    <div className="simpleTextComponent">
      <b
        className="simpleTextComponent-text regular medium"
        style={{ color: props.color ? props.color : "var(--gray700)" }}
        onMouseEnter={handleMouseEnterP}
        onMouseLeave={handleMouseLeaveP}
      >
        {JSON.parse(props.data).data[0].value}
      </b>
      {(isMouseOverP || isMouseOverPopup) && (
        <PopupInfo
          data={JSON.parse(props.data).data}
          onMouseEnter={handleMouseEnterPopup}
          onMouseLeave={handleMouseLeavePopup}
        />
      )}
    </div>
  ) : (
    <b
      className="simpleTextComponent-text regular medium"
      style={{ color: props.color ? props.color : "var(--gray700)" }}
    >
      {props.data}
    </b>
  );
};

export default SimpleTextComponent;
